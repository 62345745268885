@import "./../../../assets/css/variables.scss";

header.header {
    background-color: $color-primary;
    min-height: 65px;
    display: flex;

    .container {
        width: 100%;
    }


    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;

        .spilt-line {
            height: 60px;
            border-right: 3px solid #5e606a;
            display: inline-block;
            vertical-align: middle;
        }

        .brand-logo {
            margin-left: 10%;

            img {
                // width: 120px;
                height: 65px;
                vertical-align: middle;
                //border-right: 3px solid #5e606a;
                padding: 10px 20px 10px 0;
            }

            a {
                text-decoration: none;
                color: $color-white;
            }

            .btn-logo {
                display: inline-block;
                font-size: 24px;
                font-weight: 800;
                vertical-align: middle;
                //border-right: 3px solid #5e606a;
                padding: 12px 20px 15px 0;
                letter-spacing: 1px;
            }

            .btn-home {
                background-color: $color-white;
                color: $color-black;
                display: inline-block;
                padding: 8px 12px;
                border-radius: 4px;
                font-size: 16px;
                margin-left: 15px;
            }
        }
    }

    .user-name-button {
        &.MuiButton-root {
            height: 30px;
            width: 30px;
            min-width: 30px;
            background: darken($color-lightblue, 0%);
            border-radius: 50%;
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
            color: #fff;

            &:hover {
                background: darken($color-lightblue, 10%);
            }

            .MuiButton-label {
                color: white;
            }
        }
    }

    .dropdown-switch-title {
        margin: 5px 10px;
        color: #a7afc3;
        font-weight: 500;
    }
}

.caret {
    display: inline-block;
    border-top: 5px solid $color-white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    vertical-align: middle;
    margin-left: 5px;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.dropdown-lang-wrapper .caret {
    position: absolute;
    display: inline-block;
    transform: translateY(100%) rotate(-90deg);
    border-top: 5px solid $color-blue;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    vertical-align: middle;
    right: 10px;
}

.helpLibraryDropdownIcon {
    height: 22px !important;
    width: 22px !important;
    margin-right: 3px !important;
    vertical-align: middle;
}

.helpAndLibraryDropDown {
    position: relative;
    display: flex;
    background-color: transparent;
    color: $color-white;
    cursor: pointer;
    border: none;
    font-size: 14px;
    padding: 0px;
    outline: none;
    align-items: center;
}

.dropdownMenuClass {
    top: 62px !important;
}

.builder-version {
    background: #152c73;
    color: #fff;
    margin: 5px 0;
    padding: 5px 40px;
}

.dropdown-right {
    display: flex;

    .dropdown {
        position: relative;
        margin-left: 40px;
        display: flex;
        align-items: center;

        span {
            color: $color-white;
            cursor: pointer;

            img {
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        .profile-dropdown-menu {
            position: absolute;
            top: 47px;
            // left: 0;
            right: -10px;
            background-color: $color-white;
            width: 250px;
            list-style: none;
            margin: 0;
            padding: 0;
            z-index: 1000000 !important;
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            border: 1px solid grey;

            li {
                padding: 8px;
                cursor: pointer;
                color: $color-btn;
                font-size: $font-13;

                &:hover {
                    background-color: $color-blue;
                    color: $color-white;
                }

                &.active {
                    background-color: $color-green;
                    color: $color-white;
                }

                img {
                    margin-right: 12px !important;
                    width: 15px;
                    height: 15px;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }

            .label-center {
                display: inline-block;
                border-radius: 10px;
                background-color: #777;
                color: $color-white;
                padding: 5px 12px;
                margin: 7px 0;
                margin-bottom: 15px;
                font-size: $font-13;
                border: none;
                outline: none;
            }

            .defaultCursor {
                cursor: default;
            }
        }

        .dropdown-menu-role {
            position: absolute;
            top: 163px;
            left: 0;
            background-color: $color-white;
            width: 250px;
            list-style: none;
            margin: 0;
            padding: 0;
            z-index: 1000000 !important;
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            border: 1px solid grey;

            li {
                padding: 8px;
                cursor: pointer;
                color: $color-btn;
                font-size: $font-13;
                text-transform: capitalize;

                &:hover {
                    background-color: $color-blue;
                    color: $color-white;
                }

                &.active {
                    background-color: $color-green;
                    color: $color-white;
                }

                img {
                    margin-right: 3px;
                    vertical-align: middle;
                }
            }

            .label-center {
                display: inline-block;
                border-radius: 10px;
                background-color: #777;
                color: $color-white;
                padding: 3px 6px;
                margin: 12px;
                font-size: $font-12;
                font-weight: 700
            }
        }

        .lang-dropdown-menu {
            position: absolute;
            // top: 58px;
            right: 250px;
            background-color: $color-white;
            width: 160px;
            list-style: none;
            margin: 0;
            padding: 0;
            z-index: 1000000 !important;
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            border: 1px solid grey;

            li {
                padding: 8px;
                cursor: pointer;
                color: $color-btn;
                font-size: $font-13;

                &:hover {
                    background-color: $color-blue;
                    color: $color-white;
                }

                &.active {
                    background-color: $color-green;
                    color: $color-white;
                }

                img {
                    margin-right: 3px;
                    vertical-align: middle;
                }
            }
        }
    }
}

.App-Breadcrumbs ol li a {
    text-decoration: none;
    color: rgb(21, 44, 115);

    &:hover {
        text-decoration: underline;
        color: #2041ac;
    }
}

.separator {
    color: white;
    font-size: 2.5rem;
}