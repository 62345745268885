#drawerApplay *{
     -webkit-box-sizing: border-box; 
       -moz-box-sizing: border-box;
        box-sizing: border-box;
        vertical-align: middle;
  }
      
  html {
     -webkit-text-size-adjust: 100%;
     -ms-text-size-adjust: 100%;
     /* font-size: 11px; */
     -webkit-tap-highlight-color: transparent;
  }

  .li_line_margin_space{margin-bottom: 4px;}
  .svgclass path { fill:  #1C81DC !important; }
  .callbackCharm ul li div span{ margin-left: 9px; color:#000;
    
  }
  .getACallBox {
    position: fixed;
    left: -15.5rem;
    top: 26%;
    z-index: 99;
    transition: all 0.6s ease;
  }
  .getACallBox .callback {
    background: #4c9431;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    position: absolute;
    width: 11.03rem;
    left: 10.6rem;
    top: 4.29rem;
    padding: 0.3rem 0 0 0rem;
    font-size: 1.1rem;
    color: #fff;
    height: 40px;
    text-align: center;
    text-decoration: none;
  }
  .getACallBox .callback:before {
    /* content: "\e"; */
    font-family: regional_icomoon !important;
    font-size: 1.3rem;
    text-align: center;
    line-height: 3;
    color: #fff;
    position: absolute;
    top: -2px;
    left: 0;
    border-right: 1px solid #fff;
    height: 40px;
    width: 40px;
  }
  .getACallBox.getCallActive {
    left: 0;
  }
  .callbackCharm
 {
    display: block;
    background: #ffffff;
    width: 30rem;
    transition: all 0.5s ease-in;
    border: 1px solid #ced6f2;
  }
  .callbackCharm h2
  {
    font-size: 1.6rem;
    color: #212121;
    font-family: Lato-Bold;
    text-align: center;
    border-bottom: 1px solid #666;
    margin: 0 2rem;
    padding: 1rem 0;
    display: block;
  }
  .callbackCharm a
  {
    text-align: right;
    display: block;
    padding: 1rem 1rem 1rem 0;
    font-size: 1.4rem;
    color: #212121;
    text-decoration: underline;
  }
  .callbackCharm ul li
  {
    cursor: pointer;
    display: block;
    font-size: 18px;
    text-align: left;
    color:#152c73;
    border-top: 1px solid #666;
    border-top: none;
    margin-top: 10px;
    border-bottom:1px solid #D6D6D6;
    margin-left: -30px;
    width: 100%;
  }
  
  .callbackCharm ul li:first-child
  {
    border-top: none;
  }
  .callbackCharm ul li:last-child
  {
    border-bottom: none;
  }
  .callbackCharm {
    width: 16rem;
  }
  