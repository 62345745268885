.icon-size {
    font-size: 40px !important;
}

.feeling-width {
    width: 50px !important;   
}

.bold-font {
    font-weight: bold;
}

.divider {
    border-right: 1px solid;
}

.single-icon {
    text-align: center;
    width: 80px;
}

.multiple-icons {
    text-align: center;
    padding-left: 10px;
    min-width: 250px;
    max-width: 250px;
    width: 250px;
}

.feedback-group {
    display: flex;
    margin: 10px 0px 15px;
    justify-content: center;
}

.checkbox-group {
    margin: 10px 15px;
}

.screenshot-group {
    margin: 10px 0px 15px;
    input[type=file]{
        display: none;
    }
}

.comments-group {
    margin-top: 10px;
    text-align: center;
}

.photo-button {
    margin-left: 15px !important;
    height: 42px !important;
    background: #263B7A !important;
    color: #ffffff !important;
    border-radius: 4px !important;
    padding: 6px 16px !important;
    margin-top: 10px !important;
}

.filename {
    color: #000000 !important;
    margin-top: 16px;
    margin-left: 10px;
}

// .cancelbutton {
//     margin: 10px !important;
//     height: 38px !important;
//     color: #3f51b5 !important;
//     border: 1px solid rgba(63, 81, 181, 0.5) !important;
//     border-radius: 4px !important;
//     // padding: 6px 16px !important;
// }

// .button-active {
//     margin: 10px 20px 10px 10px !important;
//     height: 38px !important;
//     background: #263B7A !important;
//     color: #ffffff !important;
//     border-radius: 4px !important;
//     // padding: 6px 16px !important
// }

// .button-disabled {
//     margin: 10px 20px 10px 10px !important;
//     height: 38px !important;
//     background: rgba(0, 0, 0, 0.12) !important;
//     color: #ffffff !important;
//     border-radius: 4px !important;
//     // padding: 6px 16px !important
// }


.checkbox-width {
    width: 200px;
    margin-right: 10px !important;
}

.checkbox-width-end {
    width: 100px;
}

.icon-hover:hover{
    cursor: pointer;
    background-color: transparent;
    color: #3D51B1 !important;
}

.selected-button {
    color: #3D51B1 !important;
}

.button-black {
    color: #000000 !important;
}

.tooltip {
    position: relative;
    display: inline-block;
}
  
.tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: gray;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 10px;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 80%;
    margin-left: -60px;
}
  
.tooltip:hover .tooltiptext {
    visibility: visible;
}