@import './../../../assets/css/variables.scss';

footer {
    position: relative;
    // bottom: 0;
    width: 100%;
    // left: 0;
    // right: 0;
    display: flex;
    height: 40px;
    background-color: $color-lightgrey;
    padding: 10px 0;
    box-sizing: border-box;
    box-sizing: -webkit-border-box;
    justify-content: center;
    align-items: center;

    .footer-wrapper {
        display: flex;
        justify-content: space-between;

        span {
            color: $color-black;
            font-size: $font-12;
        }

        a {
            color: $color-black;
            font-size: $font-12;
            text-decoration: none;
        }

        .links {
            a {
                margin-right: 40px;
            }
        }
    }

    .details {
        position: relative;
        // margin-right:88px;
    }
}

.message-container {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ef5f5e;
    color: #fff;
    text-align: center;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}

.marquee {
    height: 25px;
    width: 100%;

    overflow: hidden;
    position: relative;
    margin: 1px auto;
}

.marquee div {
    display: block;
    width: 200%;
    position: absolute;
    overflow: hidden;
    animation: marquee 12s linear infinite;
    margin-top: 5px;
}

.marquee span {
    float: left;
    width: 100%;
}

@keyframes marquee {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}