@import "./assets/css/variables.scss";
@import "./components/NewDashboard/AddProject/addproject.scss";

@font-face {
  font-family: 'Roboto';
  src: url("./FontStyles/Roboto-Regular.ttf");
}

* {
  font-family: 'Roboto';
}

*::selection {
  background-color: #3297fd !important;
  color: #ffffff !important;
}
body {
  margin: 0;
  // padding: 100px 0 0;
  font-family: $font-family;
  font-size: $font-14;
  overflow-y: hidden !important;
  header.header {
    // position: fixed;
    width: 100%;
    // top: 0;
    // z-index: 4;
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 30px;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #f1f1f1; 
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9d9d9d; 
}
.container {
  margin: 0 auto;
  width: 70%;
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (max-width: 1200px) {
    width: 90%;
    max-width: 1200px;
  }
}

.App-Tab {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
}

.eButton {
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $color-btn;
  color: $color-btn;
  cursor: pointer;
  &:hover {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
    .caret {
      border-top-color: $color-white;
    }
  }
  &:focus {
    outline: none;
  }
  svg {
    font-size: 16px;
    display: inline-block;
    margin-right: 8px;
  }
  .caret {
    border-top-color: $color-btn;
  }
}

.eButtonPrimary {
  background-color: $color-btn;
  color: $color-white;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $color-btn;
  cursor: pointer;
  &:hover {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
    .caret {
      border-top-color: $color-white;
    }
  }
  &:focus {
    outline: none;
  }
  svg {
    font-size: 16px;
    display: inline-block;
    margin-right: 8px;
  }
  .caret {
    border-top-color: $color-btn;
  }
}
.eButtonPrimarySelectionSummary {
  background-color: $color-btn;
  color: $color-white;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $color-btn;
  cursor: pointer;
  &:hover {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
    .caret {
      border-top-color: $color-white;
    }
  }
  &:focus {
    outline: none;
  }
  svg {
    font-size: 16px;
    display: inline-block;
    margin-right: 8px;
  }
  .caret {
    border-top-color: $color-btn;
  }
}
button[id="deleteTagSelections"] {
  margin-right: 0px;
}

.section-wrapper {
  height: calc(100vh - 66px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.horizontal-menu {
  display: flex;
  padding-top: 20px;
  position: sticky;
  top: 0px;
  z-index: 3;
  background: rgb(248, 248, 248);
}
.about {
  .k-dialog {
    width: 500px;
    .k-header {
      span {
        color: rgb(51, 51, 51);
      }
      .k-dialog-actions {
        display: none;
        span {
          color: #656565;
          font-size: 20px;
        }
      }
    }
    .k-dialog-content {
      margin-top: 0;
      .k-form-inline {
        width: calc(500px - 70px);
        padding: 20px;
        margin: 0;
        span {
          color: rgb(51, 51, 51);
        }
        div {
          display: flex;
          p {
            margin: 0;
            &:first-child {
              width: 250px;
            }
          }
          dl {
            width: 100%;
            dd {
              background: #152c73;
              color: #ffffff;
              margin: 5px 0;
              padding: 5px 40px;
            }
          }
        }
      }
    }
  }
}

@mixin dropdown-li-common {
  cursor: pointer;
  color: $color-btn;
  font-size: $font-13;
  &:hover {
    background-color: $color-blue;
    color: $color-white;
  }
  &.active {
    background-color: $color-green;
    color: $color-white;
  }
  img {
    margin-right: 3px;
    vertical-align: middle;
  }
}

@mixin listCategory {
  padding: 0px 8px !important;
}

.helpAndLibrary-li-child {
  cursor: default !important;
  font-weight: bold !important;
  color: $color-black !important;
  padding: 3px 0px !important;
  &:hover {
    background-color: transparent !important;
    color: $color-black;
  }
  &.active {
    background-color: transparent !important;
    color: $color-black;
  }
  .listDisabled {
    pointer-events: none;
    cursor: not-allowed !important;
    color: #a9a9a9 !important;
  }
  .sub-category {
    margin: 0px 8px 0px 18px;
  }
  .dropdown-sub-menu {
    list-style: none;
    padding: 0px;
    margin-top: 0.5rem;

    li {
      padding: 3px 8px 3px 36px !important;
      font-weight: normal;
      @include dropdown-li-common;
    }
  }
}
.dialog-but.center {
  float: none;
  display: flex;
  flex: 1;
  justify-content: center;
  .eButtonPrimary {
    margin: 0 10px;
  }
}
.dropdownButton {
  display: inline-block;
  position: relative;

  .dropdown-sub-menu {
    list-style: none;
    padding: 0px;
    margin-top: 2px;

    li {
      padding: 8px 18px;
      font-weight: normal;
      @include dropdown-li-common;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: auto;
    left: 0;
    width: 180px;
    min-height: 180px;
    background-color: $color-white;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 999;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid grey;

    .category {
      @include listCategory;
    }

    .dropdown-li-child {
      padding: 8px 0px;
      padding-bottom: 0;
      cursor: default;
      font-weight: bold;
      color: $color-black;
      font-size: $font-13;
      .category {
        @include listCategory;
      }
      img {
        margin-right: 3px;
        vertical-align: middle;
      }
      &:hover {
        background-color: transparent;
        color: $color-black;
      }
      &.active {
        background-color: transparent;
        color: $color-black;
      }
    }

    > li {
      padding: 8px;
      font-weight: bold;
      @include dropdown-li-common;
    }
  }
}

.searchContainerProject {
  display: flex;
  position: relative;
  border: 1px solid #ccc;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  font-size: 16px;
  width: 250px;
}

.searchIconProject {
  padding: 0.5rem;
  font-size: 16px;
  color: #152c73;
  position: inherit;
  flex-direction: row-reverse;
  cursor: pointer;
}

.searchBox {
  border: 0;
  flex: 1;
  width: 234px;
  padding: 0px 0.5rem;
}

.searchdropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: default;
}

.saveAndAddDropwDown {
  // position: fixed !important;
  height: 170px;
  bottom: 0px;
}
.searchDropdownButton {
  display: inline-block;
  position: relative;

  .dropdown-menu {
    position: absolute;
    top: auto;
    left: 0;
    background-color: $color-white;
    margin: 0;
    color: #152c73;
    padding: 0;
    width: 160px;
    list-style: none;
    z-index: 999;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid rgba(0, 0, 0, 0.12);

    li {
      padding: 8px;
      cursor: pointer;
      font-size: $font-13;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      &:hover {
        background-color: $color-blue;
        color: $color-white;
      }
      &.active {
        background-color: $color-green;
        color: $color-white;
      }
      img {
        margin-right: 3px;
        vertical-align: middle;
      }
    }
  }
}

.eButtonsimple {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #152c73;
  white-space: nowrap;
  overflow: hidden;
  padding: 7px;
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  svg {
    font-size: 16px;
    display: inline-block;
    margin-right: 8px;
  }
  .caret {
    border-top-color: $color-btn;
  }
}

.eButtonsimpleSearch {
  background-color: transparent;
  border: none;
  color: #152c73;
  white-space: nowrap;
  overflow: hidden;
  padding: 7px;
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.04);
  // }
  svg {
    font-size: 16px;
    display: inline-block;
    margin-right: 8px;
  }
  .caret {
    border-top-color: $color-btn;
  }
}

.k-pager-nav:hover {
  color: #152c73 !important;
}

.eButtondisable {
  background-color: #e5e5e5;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  border-radius: 4px;
  border: 1px #e5e5e5 solid;
  color: $color-btn;
  cursor: not-allowed;
  pointer-events: none;
  margin-left: 10px;
  svg {
    font-size: 16px;
    display: inline-block;
    margin-right: 8px;
  }
  .caret {
    border-top-color: $color-btn;
  }
}
.eButtondisableSelectionSummary {
  background-color: #e5e5e5;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px;
  border-radius: 4px;
  border: 1px #e5e5e5 solid;
  color: $color-btn;
  cursor: not-allowed;
  pointer-events: none;
  margin-right: 10px;
  svg {
    font-size: 16px;
    display: inline-block;
    margin-right: 8px;
  }
  .caret {
    border-top-color: $color-btn;
  }
}
.ErrorNotification {
  border-color: red !important;
  color: red !important;
}

.SuccessNotification {
  border-color: green !important;
  color: green !important;
}

.InfoNotification {
  border-color: rgb(0, 132, 255) !important;
  color: rgb(0, 132, 255) !important;
}

.WarningNotification {
  border-color: #ffa500 !important;
  color: #ffa500 !important;
}
.Notification {
  display: flex;
  border-style: solid;
  height: 50px;
  border-width: 10px 1px 1px 1px;
  border-radius: 0 0 7px 7px;
  align-items: center;
  padding: 0px 10px;
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.075);
  z-index: 999999;
  position: fixed;
  background-color: white;
  width: 1170px;
  max-width: calc(90% - 20px);
  margin: 0 auto;
}

.NotificationDescription {
  width: 98%;
  font-weight: 500;
  color: black !important;
  // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-family: 'Roboto';
  font-size: 16px;
  padding: 7px 25px;
  word-break: break-all;
}

.NotificationIcon {
  width: 3% !important;
}

#settingdd > ul li:hover span {
  color: $color-white;
}
#settingdd > ul li.active span {
  color: $color-white;
}

#settingdd > ul li span {
  color: $color-btn;
}

#profiledd > ul li:hover span {
  color: $color-white;
  .caret {
    border-top: 5px solid $color-white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}
#profiledd > ul li.active span {
  color: $color-white;
}

#profiledd > ul li span {
  color: $color-btn;
}
.MuiContainer-root.App-body.MuiContainer-maxWidthLg {
  padding: 0 !important;
}

.k-icon-notification {
  width: 1em;
  height: 1em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  font-size: 32px !important;
  padding: 1px;
}
.b-link {
  cursor: pointer;
}

.generateLoading {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  p {
    color: #fff;
  }
}

.dashboard-grid {
  // height: calc(100vh - 275px);
  height: 64vh;
  margin-bottom: 30px;
  .k-grid-header-wrap {
    thead {
      tr {
        th {
          span {
            position: absolute;
            right: 3px;
          }
        }
      }
    }
  }
}
.App-Breadcrumbs {
  padding: 19px 0;
}
.k-dialog-titlebar {
  background-color: #e5e5e5 !important;
  color: #333 !important;
}
.k-dialog-button-layout-stretched {
  border: none !important;
}

.k-panelbar {
  .k-item.k-state-expanded {
    & > .k-link {
      background-color: $color-panel;
      color: $color-white;
    }
  }
  & > .panel-head.k-item {
    & > .k-link {
      background-color: $color-panel;
      color: $color-white;
      padding: 10px 15px;
      cursor: pointer;
      &.k-state-selected {
        color: $color-white;
        background-color: $color-blue;
        &:hover {
          background-color: $color-blue;
        }
      }
      &.k-state-focused {
        background-color: $color-panel;
      }
      &:hover {
        color: $color-white;
        background-color: $color-blue;
      }
      .k-icon {
        color: $color-white;
      }
    }

    .panel-body {
      padding: 20px !important;

      .panel-bdy-row {
        margin-bottom: 10px;
        display: flex;
        .panel-bdy-title {
          width: 190px;
          display: inline-block;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.eButtondisable {
  margin-left: 8px !important;
}

.cmdbar-right {
  margin-left: 15px !important;
}

.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.5;
  color: #2d2323;
}

.data-magement {
  .grid-item {
    .eButton {
      min-width: 120px;
      background-color: #e9e9e9 !important;
      color: black !important;
      border-color: grey !important;
    }
    #btnDownload {
      margin-top: 25px;
    }
    #btnPushToDev,
    #btnPushToStaging {
      margin-bottom: 25px;
    }
  }
}

#progressbar {
  background-color: #e9e9e9;
  border-radius: 13px; /* (height of inner div) / 2 + padding */
  padding: 3px;
  width: 100%;
  height: 20px;
}

#progressbar > div {
  background-color: $color-primary;
  width: 0%; /* Adjust with JavaScript */
  height: 20px;
  border-radius: 10px;
}

.project-data-info {
  .project-info {
    padding-bottom: 20px;
    .MuiGrid-align-items-xs-flex-start {
      display: flex;
    }
    .text-field {
      height: 32px !important;
      .MuiFormControl-root {
        margin-top: 0px !important;
      }
    }
    .info-label {
      font-size: 12px;
      color: #656565;
      float: right;
      padding-right: 20px;
    }
    .text-area {
      .MuiTextField-root {
        width: 100%;
      }
    }
  }
  .dummy-space {
    min-height: 32px;
  }
}
.pt-10 {
  padding-top: 10px !important;
}
.fl-l {
  float: left !important;
}
#selection-menu {
  .MuiList-root {
    min-width: 215px;
    padding: 0px;
  }
}
.dm_actions {
  display: flex;
  .MuiSvgIcon-root {
    padding: 5px;
    cursor: pointer;
  }
}
.data-table {
  overflow-x: auto;
  th {
    white-space: nowrap !important;
  }
}
.body-overview-text {
  .MuiInputBase-root {
    height: 20px !important;
    max-height: 20px !important;
  }
}