//colors
$color-white: #ffffff;
$color-primary: #152c73; //#262c43
$color-black: #333333;
$color-blue: #2041ac;
$color-lightblue: #1891F6;
$color-btn: #152c73;
$color-green: #690;
$color-panel: #071E62;

$header-gradient: radial-gradient(
	52.63% 300.89% at 49.31% 251.79%,
	#1891f6 0%,
	#15205e 100%
);

//Footer colors
$color-lightgrey:#d2d2d2;

//font family
// $font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
$font-family: 'Roboto';


// font sizes
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-16: 16px;

:export {
  $whitecolor: $color-white;
  $blackcolor: $color-black;
  $primary: $color-primary;
  $fontFamilyProx: $font-family;
}
